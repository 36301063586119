import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import { useGetInvoiceStatusTrend } from '@api/queries/dashboard';
import { InvoiceChip } from '@components/styled/chip';
import { formatKRW } from '@utils/formatter';

export function StatusPaper({ status }: { status: InvoiceStatus }) {
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetInvoiceStatusTrend();
  const numberOfCustomers = useMemo(() => {
    return _.get(response?.data?.Organizations, status) ?? 0;
  }, [response?.data, status]);
  const totalCost = useMemo(() => {
    return _.get(response?.data?.Amount, status) ?? 0;
  }, [response?.data, status]);
  const background = useMemo(() => {
    switch (status) {
      case 'Invoiced':
        return 'rgba(225, 122, 0, 0.02)';
      case 'Paid':
        return 'rgba(0, 122, 209, 0.02)';
      case 'Overdue':
        return 'rgba(200, 0, 60, 0.02)';
      default:
        return 'rgba(206, 207, 222, 0.02)';
    }
  }, [status]);
  return (
    <Paper
      sx={{
        minHeight: '182px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '24px',
        background,
        backdropFilter: 'blur(2px)',
        px: '24px',
        py: '16px',
      }}
    >
      <Box sx={{ ml: '-4px' }}>
        <InvoiceChip variant="text" status={status} />
      </Box>
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {formatKRW(totalCost)}
      </Typography>
      <Typography color="textSecondary" letterSpacing="0.64px">
        {globalT('format.number.quantity', { value: numberOfCustomers })} 고객사
      </Typography>
    </Paper>
  );
}

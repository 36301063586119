import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Download, Sort, Update } from '@mui/icons-material';
import { motion, useScroll, useTransform } from 'framer-motion';
import dayjs from 'dayjs';
import { useGetInvoiceDetail } from '@api/queries/invoice';
import { SuspenseBox } from '@components/suspense';
import {
  DownloadInvoiceModal,
  InvoiceAdditionalService,
  InvoiceCloudUsage,
  InvoiceDeposit,
  InvoiceDetail,
  InvoiceDiscount,
  InvoiceEstimatedCharge,
  InvoiceExchangeRate,
  InvoiceHeader,
  InvoiceHistoryModal,
  InvoiceQuickMenu,
  InvoiceSection,
  InvoiceStatusControl,
  InvoiceSummary,
  SortCloudUsageModal,
} from '@features/invoice/detail';
import {
  INVOICE_EDIT_STATE,
  INVOICE_PUBLISH_STATE,
  INVOICE_UPDATE_STATE,
} from '@features/invoice/detail/atom';
import { BackToListButton } from '@components/ui';
import { useCurrentInfo, useToggle } from '@hooks';
import { formatDate } from '@utils/formatter';
import HistoryIcon from '@assets/icon/History.svg?react';
import Settings from '@mui/icons-material/Settings';
import CloudUsageOptionModal from '@features/invoice/detail/option';

const SIDEBAR_WIDTH = 329 as const;

function InvoiceControlPanel() {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const { invoiceId } = useParams();
  const [openDownload, toggleDownload] = useToggle();
  const [openHistory, toggleHistory] = useToggle();
  const { hasRole } = useCurrentInfo();
  const { data, isRefetching } = useGetInvoiceDetail(invoiceId as string);
  const Status = useMemo(() => {
    if (data?.Invoices?.length) {
      return data.Invoices[0].Status;
    }
    return 'Unissued';
  }, [data]);
  const InvoiceType = useMemo((): CloudProvider | undefined => {
    if (data?.Invoices?.length) {
      return data.Invoices.some((v) => v.CSP === 'aws') ? 'aws' : 'ncp';
    }
    return undefined;
  }, [data?.Invoices]);
  const LastUpdated = useMemo(() => {
    if (data?.LastUpdated) {
      if (dayjs.utc(data.LastUpdated).local().isSame(dayjs(), 'date')) {
        return formatDate(data.LastUpdated, 'HH:mm', true);
      }
      return formatDate(data.LastUpdated, 'YYYY.MM.DD HH:mm', true);
    }
    return undefined;
  }, [data]);
  const [isEdit, setIsEdit] = useRecoilState(INVOICE_EDIT_STATE);
  const setPublishState = useSetRecoilState(INVOICE_PUBLISH_STATE);
  const isInvoiceUpdating = useRecoilValue(INVOICE_UPDATE_STATE);
  const openPublish = useCallback(() => {
    setPublishState((v) => ({
      ...v,
      open: true,
      ...(Status === 'Unissued' && {
        Status: 'Invoiced',
      }),
    }));
  }, [Status, setPublishState]);
  const isUpdating = useMemo(() => {
    if (!isEdit) {
      return false;
    }
    return isInvoiceUpdating || isRefetching;
  }, [isEdit, isInvoiceUpdating, isRefetching]);
  if (!invoiceId) {
    return null;
  }
  return (
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          color: 'text.secondary',
        }}
      >
        {!isUpdating && !!LastUpdated && (
          <>
            <Update sx={{ width: '20px', height: '20px' }} />
            <Typography>{t('text.lastUpdated')}</Typography>
            <Typography>{LastUpdated}</Typography>
          </>
        )}
        {isUpdating && (
          <>
            <CircularProgress size={20} sx={{ color: '#EED700' }} />
            <Typography>{t('text.updating')}</Typography>
          </>
        )}
      </Box>
      {hasRole('MANAGER') && (
        <>
          <InvoiceStatusControl
            Status={Status}
            InvoiceId={invoiceId as string}
          />
          <Divider orientation="vertical" flexItem />
        </>
      )}
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {/*<IconButton color="sub" onClick={toggleHistory}>*/}
        {/*  <HistoryIcon />*/}
        {/*</IconButton>*/}
        <IconButton color="sub" onClick={toggleDownload}>
          <Download />
        </IconButton>
        {hasRole('MANAGER') && (
          <>
            {!isEdit && (
              <Button color="sub" onClick={() => setIsEdit(true)}>
                {globalT('button.edit')}
              </Button>
            )}
            <Button onClick={openPublish}>
              {Status === 'Unissued' ? t('button.issue') : t('button.reissue')}
            </Button>
          </>
        )}
      </Box>
      <DownloadInvoiceModal
        open={openDownload}
        onClose={toggleDownload}
        InvoiceId={invoiceId}
        InvoiceType={InvoiceType}
      />
      <InvoiceHistoryModal
        open={openHistory}
        onClose={toggleHistory}
        InvoiceId={invoiceId}
      />
    </Box>
  );
}

function InvoiceDetailBody() {
  const { t } = useTranslation('invoice');
  const [openSort, toggleSort] = useToggle();
  const [openOption, toggleOption] = useToggle();
  const { hasRole } = useCurrentInfo();
  const { invoiceId } = useParams();
  const isEdit = useRecoilValue(INVOICE_EDIT_STATE);
  const { data, setId: setInvoiceId } = useGetInvoiceDetail(
    invoiceId as string,
  );
  useEffect(() => {
    if (invoiceId) {
      setInvoiceId(invoiceId);
    }
  }, [invoiceId, setInvoiceId]);
  const showExchange = useMemo(() => {
    if (data?.Invoices?.length) {
      return data.Invoices.some((v) => v.CSP === 'aws') && hasRole('MANAGER');
    }
    return false;
  }, [data?.Invoices, hasRole]);
  const { scrollY } = useScroll();
  const sideBarTop = useTransform(
    scrollY,
    (v) => `max(${showExchange ? 456 : 0}px, ${v - 138}px)`,
  );
  if (!data?.Invoices.length || !data?.Company) {
    return null;
  }
  return (
    <>
      <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          maxWidth: `calc(100% - ${SIDEBAR_WIDTH + 16}px)`,
        }}
      >
        <InvoiceHeader
          OrganizationId={data.Invoices[0].OrganizationId}
          Status={data.Invoices[0].Status}
          Date={data.Invoices[0].Date}
          InvoiceId={data.Invoices[0].InvoiceId}
          InvoicedDate={data.Invoices[0].InvoicedDate}
          PaymentDate={data.Invoices[0].PaymentDate}
          DepositUsed={data.Invoices[0].DepositUsed}
          Name={data.Company.Name}
          Contact={data.Company.Contact}
          BusinessLocation={data.Company.BusinessLocation}
        />
        <InvoiceSection id="summary" label={t('title.summary')}>
          <InvoiceSummary Invoices={data.Invoices} />
        </InvoiceSection>
        <InvoiceSection id="detail" label={t('title.detail')}>
          <InvoiceDetail Invoices={data.Invoices} />
        </InvoiceSection>
        <InvoiceSection
          id="cloud"
          label={t('title.cloudUsage')}
          extra={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              {isEdit && (
                <Button color="sub" onClick={toggleSort} startIcon={<Sort />}>
                  정렬
                </Button>
              )}
              <Button
                color="sub"
                onClick={toggleOption}
                startIcon={<Settings />}
              >
                보기 설정
              </Button>
            </Box>
          }
        >
          <SuspenseBox>
            <InvoiceCloudUsage Invoices={data.Invoices} />
          </SuspenseBox>
        </InvoiceSection>
        <InvoiceSection id="service" label={t('title.additionalService')}>
          <InvoiceAdditionalService Invoices={data.Invoices} />
        </InvoiceSection>
        <InvoiceSection id="discount" label={t('title.discount')}>
          <InvoiceDiscount Invoices={data.Invoices} />
        </InvoiceSection>
        <InvoiceSection id="deposit" label={t('title.deposit')}>
          <InvoiceDeposit
            InvoiceId={data.Invoices[0].InvoiceId}
            DepositUsed={data.Invoices[0].DepositUsed}
            BillingCustom={data.Company.BillingCustom}
          />
        </InvoiceSection>
      </Paper>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: SIDEBAR_WIDTH,
          minWidth: SIDEBAR_WIDTH,
          maxWidth: SIDEBAR_WIDTH,
        }}
      >
        {showExchange && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              maxHeight: '440px',
            }}
          >
            <InvoiceExchangeRate Invoices={data.Invoices} />
          </Box>
        )}
        <Box
          component={motion.div}
          style={{ top: sideBarTop }}
          transition={{ delay: 3 }}
          sx={{
            position: 'absolute',
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: SIDEBAR_WIDTH,
            minWidth: SIDEBAR_WIDTH,
            maxWidth: SIDEBAR_WIDTH,
          }}
        >
          <InvoiceQuickMenu />
          <InvoiceEstimatedCharge Invoices={data.Invoices} />
        </Box>
      </Box>
      <SortCloudUsageModal
        open={openSort}
        onClose={toggleSort}
        InvoiceId={invoiceId}
      />
      <CloudUsageOptionModal open={openOption} onClose={toggleOption} />
    </>
  );
}

export default function InvoiceDetailPage() {
  return (
    <RecoilRoot>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: '6px',
          pr: '36px',
          py: '20px',
          mb: '24px',
        }}
      >
        <BackToListButton href={-1} />
        <SuspenseBox FallbackProps={{ size: 24 }}>
          <InvoiceControlPanel />
        </SuspenseBox>
      </Box>
      <SuspenseBox
        sx={{ flex: 1, display: 'flex', gap: '16px' }}
        FallbackProps={{ size: 72, sx: { flex: 1 } }}
      >
        <InvoiceDetailBody />
      </SuspenseBox>
    </RecoilRoot>
  );
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { sumBy } from 'lodash';
import { useGetInvoiceListForCurrentCompany } from '@api/queries/invoice';
import { InvoiceChip } from '@components/styled/chip';
import { formatKRW } from '@utils/formatter';

type Props = {
  status: InvoiceStatus;
};

export default function CostByStatus({ status }: Props) {
  const { t } = useTranslation('invoice');
  const { data: response } = useGetInvoiceListForCurrentCompany(1);
  const numberOfCustomers = useMemo(() => {
    if (response?.data) {
      const filtered = response.data
        .filter((v) => v.Status === status)
        .map((v) => v.OrganizationId);
      return new Set(filtered).size;
    }
    return 0;
  }, [response?.data, status]);
  const totalCost = useMemo(() => {
    if (response?.data) {
      const filtered = response.data.filter((v) => v.Status === status);
      return sumBy(filtered, 'TotalCost.KRW');
    }
    return 0;
  }, [response?.data, status]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
      }}
    >
      <InvoiceChip variant="text" status={status} />
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {formatKRW(totalCost)}
      </Typography>
      <Typography color="textSecondary" letterSpacing="0.64px">
        {t('text.numberOfCustomer', { count: numberOfCustomers })}
      </Typography>
    </Box>
  );
}

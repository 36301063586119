import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useGetCompany } from '@api/queries/company';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';
import { useState } from 'react';

export default function useGetInvoiceListForCurrentCompany(
  monthRange?: number,
) {
  const [_monthRange, setMonthRange] = useState<number | undefined>(monthRange);
  const { data: response } = useGetCompany();
  const query = useSuspenseQuery({
    queryKey: [
      ...INVOICE_KEY.GET_INVOICE_LIST_BY_COMPANY_ID,
      response?.data.UID,
      _monthRange,
    ],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? InvoiceService.getInvoiceListByCompanyId(
            queryKey[2] as string,
            queryKey[3] as number | undefined,
          )
        : null,
  });
  return { ...query, setMonthRange };
}

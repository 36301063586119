import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Link } from '@heka/theme';
import { useGetCurrentUser } from '@api/queries/user';
import { useGetDashboardTrend } from '@api/queries/dashboard';

export default function UnissuedInvoice() {
  const { t } = useTranslation('dashboard');
  const { data: user } = useGetCurrentUser();
  const { data: response } = useGetDashboardTrend();
  return (
    <>
      <Typography
        color="#362F26"
        fontWeight={500}
        mb="16px"
        dangerouslySetInnerHTML={{
          __html: t('text.invoiceTasks', {
            name: user?.data.FirstName ?? '',
            value: response?.data?.NumberOfUnissuedInvoices ?? 0,
          }),
        }}
      />
      <Box>
        <Link size="comfort" href="/invoice">
          {t('text.startInvoiceTask')}
        </Link>
      </Box>
    </>
  );
}

import AxiosClient from '@api/client';
import { getFilenameFromHeader } from '@utils/blob';
import { formatDate } from '@utils/formatter';

const axios = AxiosClient.getInstance();
const PREFIX = '/invoice' as const;

async function getInvoices() {
  const { data } = await axios.get<MultipleResponse<Invoice>>(PREFIX);
  return data;
}

async function getInvoicesByCompanyId(CompanyId: string) {
  const { data } = await axios.get<MultipleResponse<Invoice>>(
    `${PREFIX}/company/${CompanyId}`,
  );
  return data;
}

async function getInvoiceListByCompanyId(
  CompanyId: string,
  MonthRange?: number,
) {
  const { data } = await axios.get<MultipleResponse<InvoiceList>>(
    `${PREFIX}/company/${CompanyId}/list`,
    {
      params: {
        month_range: MonthRange,
      },
    },
  );
  return data;
}

async function getInvoicesByInvoiceId(InvoiceId: string) {
  const { data } = await axios.get<
    MultipleResponse<Invoice, { LastUpdated?: Date }>
  >(`${PREFIX}/id/${InvoiceId}`);
  return data;
}

async function getInvoiceByAccountId(InvoiceId: string, AccountId: string) {
  const { data } = await axios.get<SingleResponse<InvoiceCloudUsage>>(
    `${PREFIX}/id/${InvoiceId}/account/${AccountId}`,
  );
  return data;
}

async function getInvoiceById(UID: string) {
  const { data } = await axios.get<SingleResponse<Invoice>>(`${PREFIX}/${UID}`);
  return data;
}

async function getInvoiceHistory(InvoiceId: string) {
  const { data } = await axios.get<MultipleResponse<ActivityLog>>(
    `${PREFIX}/${InvoiceId}/history`,
  );
  return data;
}

async function updateInvoiceStatus(
  InvoiceId: string,
  {
    Status,
    InvoicedDate,
    PaymentDate,
    EmailList,
    Attached,
  }: UpdateInvoiceStatus,
) {
  const { data } = await axios.put<MultipleResponse<Invoice>>(
    `${PREFIX}/id/${InvoiceId}/status`,
    {
      Status,
      InvoicedDate: InvoicedDate
        ? formatDate(InvoicedDate, 'YYYY-MM-DDT00:00:00')
        : undefined,
      PaymentDate: PaymentDate
        ? formatDate(PaymentDate, 'YYYY-MM-DDT00:00:00')
        : undefined,
      EmailList,
      Attached,
    },
  );
  return data;
}

async function updateInvoiceCloudUsage(
  InvoiceId: string,
  CSP: CloudProvider,
  body: UpdateInvoiceCloudUsage,
) {
  const { data } = await axios.put<MultipleResponse<Invoice>>(
    `${PREFIX}/id/${InvoiceId}/cloud/${CSP}`,
    body,
  );
  return data;
}

async function restoreInvoiceCloudUsage(InvoiceId: string) {
  const { data } = await axios.put<MultipleResponse<Invoice>>(
    `${PREFIX}/id/${InvoiceId}/restore`,
  );
  return data;
}

async function sortInvoiceCloudUsage(
  InvoiceId: string,
  body: SortInvoiceCloudUsage,
) {
  const { data } = await axios.post<MultipleResponse<Invoice>>(
    `${PREFIX}/id/${InvoiceId}/cloud/sort`,
    body,
  );
  return data;
}

async function updateInvoiceCustom(
  InvoiceId: string,
  CSP: CloudProvider | undefined = undefined,
  body: UpdateInvoiceCustom,
) {
  const { data } = await axios.put<MultipleResponse<Invoice>>(
    `${PREFIX}/id/${InvoiceId}/custom${CSP ? `/${CSP}` : ''}`,
    body,
  );
  return data;
}

async function downloadInvoiceListAsCsv(CompanyId: string) {
  const { data, headers } = await axios.get(
    `${PREFIX}/company/${CompanyId}/list/csv`,
    {
      responseType: 'blob',
    },
  );
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

async function downloadInvoiceAsPdf(
  InvoiceId: string,
  ExcludeZero: boolean = false,
) {
  const { data, headers } = await axios.get(`${PREFIX}/id/${InvoiceId}/pdf`, {
    ...(!!ExcludeZero && {
      params: {
        exclude_zero: ExcludeZero,
      },
    }),
    responseType: 'blob',
  });
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

async function downloadInvoiceAsExcel(InvoiceId: string) {
  const { data, headers } = await axios.get(`${PREFIX}/id/${InvoiceId}/excel`, {
    responseType: 'blob',
  });
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

async function downloadInvoiceTransaction(InvoiceId: string) {
  const { data, headers } = await axios.get(
    `${PREFIX}/id/${InvoiceId}/transaction`,
    {
      responseType: 'blob',
    },
  );
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

async function downloadInvoiceAsCsv(InvoiceId: string) {
  const { data, headers } = await axios.get(`${PREFIX}/id/${InvoiceId}/csv`, {
    responseType: 'blob',
  });
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

export default {
  getInvoices,
  getInvoicesByCompanyId,
  getInvoiceListByCompanyId,
  getInvoicesByInvoiceId,
  getInvoiceByAccountId,
  getInvoiceById,
  getInvoiceHistory,
  updateInvoiceStatus,
  updateInvoiceCloudUsage,
  restoreInvoiceCloudUsage,
  sortInvoiceCloudUsage,
  updateInvoiceCustom,
  downloadInvoiceListAsCsv,
  downloadInvoiceAsPdf,
  downloadInvoiceAsExcel,
  downloadInvoiceTransaction,
  downloadInvoiceAsCsv,
};

import type { MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Button, ClickAwayListener, Popper } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { SuspensePaper } from '@components/suspense';
import { NHN_SALES_FILTER_STATE } from '@atoms/page/filter';
import NHNSalesFilterAutocomplete from './NHNSalesFilterAutocomplete';

function NHNSalesManagementFilterBody({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation('global', {
    keyPrefix: 'component.filter',
  });
  const [filter, setFilter] = useRecoilState(NHN_SALES_FILTER_STATE);
  const [filterRaw, setFilterRaw] = useState(filter);
  const applyChange = useCallback(() => {
    onClose();
    setTimeout(() => {
      setFilter(filterRaw);
    }, 500);
  }, [filterRaw, onClose, setFilter]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {/*<NHNSalesFilterAutocomplete*/}
        {/*  basis="project"*/}
        {/*  filter={filterRaw}*/}
        {/*  setFilter={setFilterRaw}*/}
        {/*/>*/}
        <NHNSalesFilterAutocomplete
          basis="org"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
      </Box>
      <Button color="emphasis1" onClick={applyChange} fullWidth>
        {t('button.apply')}
      </Button>
    </Box>
  );
}

export default function NHNSalesManagementFilter() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleOpen = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <Box>
      <Button color="sub" onClick={handleOpen} startIcon={<FilterList />}>
        필터
      </Button>
      <Popper
        id={open ? 'heka-sales-filter-popper' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 2,
        })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={{ display: 'flex', width: '320px', maxWidth: '320px' }}>
            <SuspensePaper
              sx={{
                flex: 1,
                border: 'none',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
                backdropFilter: 'blur(3px)',
              }}
            >
              <NHNSalesManagementFilterBody onClose={handleClose} />
            </SuspensePaper>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}

import { Unstable_Grid2 as Grid } from '@mui/material';
import { SuspenseBox } from '@components/suspense';
import { useCurrentInfo } from '@hooks';
import {
  AccountChart,
  AccountTrend,
  CloudTypeChart,
  CustomerChart,
  CustomerStatus,
  CustomerTrend,
  DashboardPaper,
  InvoiceChart,
  InvoiceRank,
  InvoiceStatus,
  OutstandingTrend,
  PartnerChart,
  RevenueChart,
  RevenueRank,
  RevenueTrend,
  SalesChart,
  ServiceRevenueChart,
  ServiceUsageChart,
  UnissuedInvoice,
} from '@features/dashboard';

function UserDashboard() {
  return (
    <Grid container spacing="16px">
      <Grid desktop={8} zero={12}>
        <DashboardPaper sx={{ display: 'flex', flexDirection: 'column' }}>
          <InvoiceChart />
        </DashboardPaper>
      </Grid>
      <Grid desktop={4} zero={12}>
        <DashboardPaper>
          <ServiceRevenueChart />
        </DashboardPaper>
      </Grid>
      <Grid desktop={8} zero={12}>
        <DashboardPaper>
          <RevenueChart />
        </DashboardPaper>
      </Grid>
      <Grid desktop={4} zero={12}>
        <DashboardPaper>
          <CloudTypeChart />
        </DashboardPaper>
      </Grid>
    </Grid>
  );
}

export default function DashboardPage() {
  const { hasRole } = useCurrentInfo();
  return (
    <SuspenseBox sx={{ flex: 1 }} FallbackProps={{ size: 96, sx: { flex: 1 } }}>
      {hasRole('MANAGER') ? (
        <Grid container spacing="16px">
          {/* Trend */}
          <Grid tablet={4} zero={12}>
            <DashboardPaper>
              <UnissuedInvoice />
            </DashboardPaper>
          </Grid>
          <Grid tablet={4} zero={12}>
            <DashboardPaper>
              <CustomerTrend />
            </DashboardPaper>
          </Grid>
          <Grid tablet={4} zero={12}>
            <DashboardPaper>
              <AccountTrend />
            </DashboardPaper>
          </Grid>
          <Grid tablet={6} zero={12}>
            <DashboardPaper>
              <RevenueTrend />
            </DashboardPaper>
          </Grid>
          <Grid tablet={6} zero={12}>
            <DashboardPaper>
              <OutstandingTrend />
            </DashboardPaper>
          </Grid>
          {/* Status */}
          <Grid desktop={7} zero={12}>
            <DashboardPaper sx={{ display: 'flex', flexDirection: 'column' }}>
              <InvoiceChart />
            </DashboardPaper>
          </Grid>
          <Grid desktop={5} zero={12}>
            <DashboardPaper>
              <InvoiceStatus />
            </DashboardPaper>
          </Grid>
          <Grid zero={12}>
            <DashboardPaper>
              <SalesChart />
            </DashboardPaper>
          </Grid>
          <Grid desktop={7} zero={12}>
            <DashboardPaper>
              <RevenueChart />
            </DashboardPaper>
          </Grid>
          <Grid desktop={5} zero={12}>
            <DashboardPaper>
              <CustomerStatus />
            </DashboardPaper>
          </Grid>
          <Grid desktop={6} zero={12}>
            <DashboardPaper>
              <CustomerChart />
            </DashboardPaper>
          </Grid>
          <Grid desktop={6} zero={12}>
            <DashboardPaper>
              <AccountChart />
            </DashboardPaper>
          </Grid>
          {/* Rank */}
          <Grid desktop={6} zero={12}>
            <DashboardPaper sx={{ overflowY: 'auto' }}>
              <RevenueRank />
            </DashboardPaper>
          </Grid>
          <Grid desktop={6} zero={12}>
            <DashboardPaper sx={{ overflowY: 'auto' }}>
              <InvoiceRank />
            </DashboardPaper>
          </Grid>
          <Grid desktop={8} zero={12}>
            <DashboardPaper>
              <ServiceUsageChart />
            </DashboardPaper>
          </Grid>
          <Grid desktop={4} tablet={6} zero={12}>
            <DashboardPaper sx={{ display: 'flex', flexDirection: 'column' }}>
              <ServiceRevenueChart />
            </DashboardPaper>
          </Grid>
          {/*<Grid full={3} desktop={4} tablet={6} zero={12}>*/}
          {/*  <DashboardPaper>*/}
          {/*    <CloudTypeChart />*/}
          {/*  </DashboardPaper>*/}
          {/*</Grid>*/}
        </Grid>
      ) : (
        <UserDashboard />
      )}
    </SuspenseBox>
  );
}

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { sumBy } from 'lodash';
import { useGetRevenueTrend } from '@api/queries/dashboard';
import { DashboardNotFound } from '@features/dashboard/styled';
import DashboardInvoiceChart from '@components/chart/dashboard/invoice';

export default function InvoiceChart() {
  const { t } = useTranslation('dashboard');
  const { data: response } = useGetRevenueTrend();
  const isDataEmpty = useMemo(() => {
    return !sumBy(response?.data, 'TotalAmount');
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.invoiceTrend')}
      </Typography>
      {!isDataEmpty ? (
        <DashboardInvoiceChart
          data={response?.data.map((v) => ({
            ...v,
            TotalAmount: v.TotalAmount,
          }))}
        />
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}

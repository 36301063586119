import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Payment } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateInvoiceCustom } from '@api/queries/invoice';
import { useCurrentInfo } from '@hooks';
import { CurrencyInput } from '@features/customer/form/deposit/styled';
import DepositTable from '@components/table/deposit';
import { InvoiceSectionHeader } from '../styled';

type Props = Pick<Invoice, 'InvoiceId' | 'DepositUsed'> &
  Pick<Organization, 'BillingCustom'>;
export default function InvoiceDeposit({
  InvoiceId,
  DepositUsed,
  BillingCustom,
}: Props) {
  const { t } = useTranslation('invoice');
  const { t: customerT } = useTranslation('customer');
  const { hasRole } = useCurrentInfo();
  const { mutateAsync, isPending } = useUpdateInvoiceCustom();
  const {
    values,
    isValid,
    dirty,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<{ DepositUsed: number | undefined }>({
    initialValues: {
      DepositUsed: DepositUsed,
    },
    validationSchema: Yup.object().shape({
      DepositUsed: Yup.number().min(0).defined(),
    }),
    onSubmit: async (v) => {
      try {
        await mutateAsync({
          InvoiceId,
          DepositUsed: Number(v.DepositUsed),
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<DepositHistory>();
    return [
      columnHelper.accessor('Amount', {
        id: 'Amount',
      }),
      columnHelper.accessor('UsageDate', {
        id: 'UsageDate',
      }),
      columnHelper.accessor('Remaining', {
        id: 'Remaining',
      }),
    ];
  }, []);
  const remainingDeposit = useMemo(() => {
    if (BillingCustom.DepositInfo.DepositHistory.length) {
      return BillingCustom.DepositInfo.DepositHistory[
        BillingCustom.DepositInfo.DepositHistory.length - 1
      ].Remaining;
    }
    return 0;
  }, [BillingCustom.DepositInfo.DepositHistory]);
  useEffect(() => {
    resetForm({
      values: {
        DepositUsed: DepositUsed,
      },
    });
    if (hasRole('MANAGER') && !DepositUsed) {
      setFieldValue('DepositUsed', undefined);
    }
  }, [DepositUsed, resetForm, setFieldValue, hasRole]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
    >
      <InvoiceSectionHeader title={t('title.deposit')} />
      <CurrencyInput
        name="DepositUsed"
        value={values.DepositUsed}
        onValueChange={(v) => setFieldValue('DepositUsed', v.floatValue)}
        onBlur={handleBlur}
        placeholder={t('text.depositHolder')}
        readOnly={!hasRole('MANAGER')}
        valueIsNumericString
      />
      {hasRole('MANAGER') && (
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
            {...(!DepositUsed && {
              startIcon: <Payment />,
            })}
          >
            {DepositUsed ? t('button.changeDeposit') : t('button.useDeposit')}
          </LoadingButton>
        </Box>
      )}
      <Box>
        <Typography color="textSecondary" mb="16px">
          {customerT('text.currentDepositBalance')}
        </Typography>
        <Typography color="text.main" fontSize="24px" fontWeight={500}>
          {customerT('text.depositValue', { value: remainingDeposit })}
        </Typography>
      </Box>
      {Boolean(BillingCustom.DepositInfo.DepositHistory.length) && (
        <DepositTable
          columns={columns}
          data={BillingCustom.DepositInfo.DepositHistory}
        />
      )}
    </Box>
  );
}

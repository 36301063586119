import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useGetDashboardTrend } from '@api/queries/dashboard';
import { DifferenceSection } from '../styled';

export default function AccountTrend() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetDashboardTrend();
  const difference = useMemo(() => {
    if (response?.data) {
      return (
        Number(response.data.NumberOfAccounts) -
        Number(response.data.NumberOfLastMonthAccounts)
      );
    }
    return 0;
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.currentAccount')}
      </Typography>
      <Typography color="#362F26" fontSize="28px" fontWeight={500} mb="8px">
        {globalT('format.number.quantity', {
          value: response?.data?.NumberOfAccounts ?? 0,
        })}
      </Typography>
      <DifferenceSection formatKey="quantity" difference={difference} />
    </>
  );
}

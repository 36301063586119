import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useGetDashboardTrend } from '@api/queries/dashboard';

export default function OutstandingTrend() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetDashboardTrend();
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.thisMonthOutstanding')}
      </Typography>
      <Typography color="#362F26" fontSize="28px" fontWeight={500}>
        {globalT('format.currency.krw', {
          value: response?.data?.ThisMonthOutstandingAmount ?? 0,
        })}
      </Typography>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '8px', mt: '14px' }}
      >
        <Typography color="textSecondary">
          {t('text.accumulatedOutstanding')}
        </Typography>
        <Typography color="textSecondary">
          {globalT('format.currency.krw', {
            value: response?.data?.TotalOutstandingAmount ?? 0,
          })}
        </Typography>
      </Box>
      {/*<DifferenceSection formatKey="percent" difference={difference} />*/}
    </>
  );
}
